import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import RackImage from 'i/rack/rack.jpg';
import { Transition } from 'react-transition-group';
import { ReactComponent as SmartRetourIcon } from 'i/rack/SmartRetour.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { UiContext } from 'context/ui';

const RackReturnLauncher = () => {
	const $bgRef = useRef();
	const $buttonRef = useRef();
	const $rackIdRef = useRef();
	const [isOpen, setOpen] = useState(false);
	const [rackId, setRackId] = useState(false);
	const { isMainMenuOpen } = useContext(UiContext);
	const navigate = useNavigate();
	const { t } = useTranslation('rackReturnLauncher');

	const onEntering = (node) => {
		gsap.fromTo(node, {
			opacity: 0,
			height: 0,
			ease: 'power3.InOut',
			duration: 0.3,
		}, {
			opacity: 1,
			height: 'auto',
		});
	};
	const onExit = (node) => {
		gsap.fromTo(
			node,
			{
				opacity: 1,
				height: 'auto',
			},
			{
				opacity: 0,
				height: 0,
				ease: 'power3.InOut',
				duration: 0.3,
			},
		);
	};

	const toggleRackReturnForm = () => {
		setOpen(!isOpen);
	};

	const handleRackIdBlur = (e) => {
		const cl = $rackIdRef.current?.classList;
		if (e.target.value) {
			setRackId(e.target.value);
			cl.remove('glow');
			cl.add('valid');
		} else {
			cl.remove('valid');
			setRackId(false);
		}
	};

	const submitRackReturn = (e) => {
		e.preventDefault();
		const id = rackId || e.target.value;
		if (id) {
			navigate(`/retour/${id}`);
		} else {
			const cl = $rackIdRef.current?.classList;
			if (cl) {
				cl.add('glow');
				cl.add('shake');
				setTimeout(() => $rackIdRef.current?.classList.remove('shake'), 500);
			}
		}
	};

	const handleRackIdKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleRackIdBlur(e);
			submitRackReturn(e);
		}

		const isControlKey = (
			e.key === 'Backspace'
			|| e.key === 'Delete'
			|| e.key === 'ArrowLeft'
			|| e.key === 'ArrowRight'
		);
		const isDigit = (e.key >= '0' && e.key <= '9');
		const hasSelection = e.target.selectionStart !== e.target.selectionEnd;

		if (!(isControlKey || isDigit)
			|| (isDigit && e.target.value.length >= 3 && !hasSelection)) {
			e.preventDefault();
		}
	};

	const bgClickHandler = (e) => {
		if (e.target === $bgRef.current && isOpen) {
			setOpen(false);
		}
	};

	// keep bottom alignment in sync with crip-chat's
	useEffect(() => {
		const crispChatbox = document.getElementById('crisp-chatbox');
		if (!crispChatbox || !$buttonRef.current) return () => { };

		const updateButtonPos = () => {
			if (!crispChatbox || !$buttonRef.current) return;
			if (crispChatbox.getAttribute('data-full-view') === 'true') {
				$buttonRef.current.classList.add('crisp-chatbox-full-view');
			} else {
				$buttonRef.current.classList.remove('crisp-chatbox-full-view');
			}
		};

		updateButtonPos();
		const observer = new MutationObserver(updateButtonPos);
		observer.observe(crispChatbox, { attributes: true, attributeFilter: ['data-full-view'] });

		return () => {
			observer?.disconnect();
		};
	}, []);

	/* eslint-disable jsx-a11y/click-events-have-key-events */
	/* eslint-disable jsx-a11y/no-static-element-interactions */
	if (isMainMenuOpen) {
		return null;
	}
	return (
		<div className={`rr_ln${isOpen ? ' open' : ''}`} onClick={bgClickHandler} ref={$bgRef}>
			<button type="button" className="rr_ln_button" onClick={toggleRackReturnForm} ref={$buttonRef}>
				{
					isOpen
						? <span className="rr_ln_button_icon__close" />
						: <SmartRetourIcon className="rr_ln_button_icon__open" />
				}
			</button>
			<Transition
				in={isOpen}
				timeout={1800}
				onExit={onExit}
				onEntering={onEntering}
				mountOnEnter
				unmountOnExit
			>
				<form className="rr_ln_popup">
					<div className="rr_ln_rackpick">
						<img src={RackImage} alt="" />
						<input
							className="rr_ln_form_input"
							type="number"
							maxLength={3}
							onBlur={handleRackIdBlur}
							onKeyDown={handleRackIdKeyDown}
							placeholder=""
							ref={$rackIdRef}
						/>
					</div>
					<button type="submit" className="btn_v2 rr_ln_form_submit" onClick={submitRackReturn}>
						{t('continueToReturnForm')}
					</button>
				</form>
			</Transition>
		</div>
	);
};

export default RackReturnLauncher;
