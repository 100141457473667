/* eslint-disable quotes,react/jsx-one-expression-per-line,camelcase */
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { BodyBg } from 'components/BodyBg';
import { ErrorMessage, Formik } from 'formik';
import LogoImage from 'i/logo.svg';
import PosImage from 'i/icons/pos.svg';
import i18n from 'utils/i18n';
import emailjs from '@emailjs/browser';
import ReturnRackMap from './ReturnRackMap';
import ReturnRackConfirmation from './ReturnRackConfirmation';
import ReturnRackLangList from './ReturnRackLangList';

const ReturnRackPage = () => {
	const [displayedStreet, setDisplayedStreet] = useState('');
	const [displayedCity, setDisplayedCity] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [mapData, setMapData] = useState({ resolved: false });
	const [companyName, setCompanyName] = useState('');
	const [contactName, setContactName] = useState('');
	const [comments, setComments] = useState('');
	const [addressFieldEnabled, setAddressFieldEnabled] = useState(true);
	const { id: rackId } = useParams();
	const useGeolocation = useRef("geolocation" in navigator);
	const geoWatcher = useRef(null);
	const [trackLocation, setTrackLocation] = useState(true);
	const [errorTrackingLocation, setErrorTrackingLocation] = useState(false);
	const formRef = useRef(null);
	const { t } = useTranslation('rackReturn');
	const langDE = { lng: 'de' };
	const optionalSubtitle = null;
	// a bit redundant but it's how it comes from WordPress
	const contact_info = {
		address_1: {
			en: "Oberwilstrasse 3",
			de: "Oberwilstrasse 3",
			fr: "Oberwilstrasse 3",
			it: "Oberwilstrasse 3",
		},
		address_2: {
			en: "CH-3253 Schnottwil",
			de: "CH-3253 Schnottwil",
			fr: "CH-3253 Schnottwil",
			it: "CH-3253 Schnottwil",
		},
		contact_1_phone: "+41 (0) 62 961 80 30",
		contact_2_email: "info@cdr.ch",
	};
	const langApp = i18n.language;

	const telLink = (telNr) => {
		let cleanNumber = telNr.replace(/[^\d+]/g, '');
		if (cleanNumber.startsWith('+41') || cleanNumber.startsWith('0041')) {
			if (cleanNumber[0] === '+') {
				cleanNumber = cleanNumber.substring(3); // Remove the '+41' country code
			} else {
				cleanNumber = cleanNumber.substring(4); // Remove the '0041' country code
			}
			if (!cleanNumber.startsWith('0') && cleanNumber.length === 9) {
				cleanNumber = `0${cleanNumber}`;
			}
		}
		return `tel:${cleanNumber}`;
	};

	const positionNotAvailable = (e) => {
		if (e) {
			if (!errorTrackingLocation) {
				setErrorTrackingLocation(true);
			}
			// switch (e.code) {
			// 	case e.POSITION_UNAVAILABLE: break;
			// 	case e.PERMISSION_DENIED: break;
			// 	case e.TIMEOUT: break;
			// 	case e.UNKNOWN: break;
			// }
		}
		setAddressFieldEnabled(true);
	};

	const updatePosition = useCallback(position => {
		const coords = position?.coords;
		if (coords?.latitude) {
			const { latitude, longitude, accuracy } = coords;
			if (!mapData.coordinates || mapData.coordinates.latitude !== latitude || mapData.coordinates.longitude !== longitude) {
				setMapData({
					resolved: false,
					coordinates: { latitude, longitude, accuracy },
				});
			}
		} else {
			positionNotAvailable();
		}
	}, [mapData]);

	const startLocationTracking = () => {
		if (useGeolocation.current) {
			if (geoWatcher.current) {
				return;
			}
			geoWatcher.current = navigator.geolocation.watchPosition(
				updatePosition,
				positionNotAvailable,
				{
					enableHighAccuracy: true, // Request the best possible results
					timeout: 10000, // Maximum time to wait for a location (10 seconds)
					maximumAge: 0, // Accept only the freshest data
				},
			);
		} else {
			positionNotAvailable();
		}
	};

	const stopLocationTracking = () => {
		navigator.geolocation.clearWatch(geoWatcher.current);
		geoWatcher.current = null;
	};

	const toggleGeoWatcher = () => {
		if (trackLocation) {
			stopLocationTracking();
		} else {
			startLocationTracking();
			if (errorTrackingLocation) {
				// reset tracking error
				setErrorTrackingLocation(false);
			}
		}
		setTrackLocation(!trackLocation);
	};

	const sendRackPickupEmail = async (values) => {
		const gmapLink = `https://www.google.ch/maps/search/${encodeURIComponent(mapData.displayedAddress.join(', '))}`;
		const encodeHTML = (str) => {
			return `${str}`.replace(/[&<>"']/g, (tag) => {
				const charsToReplace = {
					'&': '&amp;',
					'<': '&lt;',
					'>': '&gt;',
					'"': '&quot;',
					"'": '&#39;',
				};
				return charsToReplace[tag] || tag;
			});
		};
		const headingVars = {
			rackId,
			companyName,
			city: displayedCity,
		};
		const vars = {
			rackId,
			heading: (
				values.companyName
					? t('rackReturnEmail:rackIdReadyAtCompanyInCityFmt', langDE)
					: t('rackReturnEmail:rackIdReadyInCityFmt', langDE)
			).replace(/\{(\w+)\}/g, (_match, key) => encodeHTML(headingVars[key]) || ''),
			companyName: encodeHTML(companyName),
			contactName: encodeHTML(contactName),
			address: `${encodeHTML(mapData.displayedAddress.join(', '))} <a href="${gmapLink}">(Google Maps)</a>`,
			language: encodeHTML(langApp),
			comments: encodeHTML(comments).replace(/\n/g, '<br>'),
		};
		const htmlEmailBody = t('rackReturnEmail:bodyHtmlFmt', langDE).replace(/\{(\w+)\}/g, (_match, key) => vars[key] || '');
		const emailProps = {
			subject: t('rackReturnEmail:subjectFmt', langDE).replace('{}', rackId),
			html_body: htmlEmailBody,
			from_name: 'CDR Retouren',
			from_email: 'retour@cdr.ch',
			to_email: 'spedition@cdr.ch',
			cc_email: 'gabriel@cdr.ch',
			bcc_email: 'andy@bitcreed.us',
		};

		await emailjs.send(
			process.env.REACT_APP_EMAILJS_SERVICE_ID,
			process.env.REACT_APP_EMAILJS_GENERIC_TEMPLATE_ID,
			emailProps,
			{
				publicKey: process.env.REACT_APP_EMAILJS_USER_ID,
			},
		);
	};

	useEffect(() => {
		startLocationTracking();
		document.body.classList.add('page_rr');
	}, []);

	useEffect(() => {
		if (displayedStreet !== '' && displayedCity !== ''
			&& (!mapData.displayedAddress
				|| (
					displayedStreet !== mapData.displayedAddress[0]
					&& displayedCity !== mapData.displayedAddress[1]
				)
			)) {
			setMapData({ resolved: false, displayedAddress: [displayedStreet, displayedCity] });
		}
	}, [displayedStreet, displayedCity]);

	useEffect(() => {
		if (mapData.resolved) {
			// coords are resolved, switch to map-only entry
			if (displayedStreet !== mapData.displayedAddress[0]) {
				setDisplayedStreet(mapData.displayedAddress[0]);
			}
			if (displayedCity !== mapData.displayedAddress[1]) {
				setDisplayedCity(mapData.displayedAddress[1]);
			}
			if (addressFieldEnabled) {
				setAddressFieldEnabled(false);
			}
			if (mapData.locationType === 'map-point' && trackLocation) {
				toggleGeoWatcher();
			}
		}
	}, [mapData]);

	useEffect(() => {
		const ref = formRef.current;
		if (!ref) return;
		if (ref && !ref.isValid) {
			ref.validateForm();
		}
	}, [i18n.language]);

	const positionStatus = trackLocation
		? (errorTrackingLocation
			? 'error'
			: (mapData?.coordinates?.accuracy < 40 ? 'found' : 'acquiring'))
		: 'off';

	return (
		<>
			<BodyBg />
			<header className="rr_header">
				<div className="rr_header_in">
					<div className="rr_header_logo_address">
						<Link
							className="rr_header_logo"
							to="/"
						>
							<img
								className="rr_header_logo_img" // rr_logo
								src={LogoImage}
								alt="CDR Logo"
							/>
						</Link>
						<div className="rr_header_info_list_w">
							<ul className="rr_header_info_list">
								{
									Object.keys(contact_info).map((key, index) => {
										let link = null;

										if (key.includes('phone')) {
											link = telLink(contact_info[key].trim());
										} else if (key.includes('email')) {
											link = `mailto:${contact_info[key].trim()}`;
										}

										return (
											<li className="rr_header_info_item" key={index}>
												{link ? (
													<a className="rr_header_info_link" href={`${link}`}>{contact_info[key]}</a>
												) : (
													<div className="rr_header_info_item_descr">{contact_info[key][langApp]}</div>
												)}
											</li>
										);
									})
								}
							</ul>
						</div>
					</div>
					<ReturnRackLangList />
				</div>
			</header>
			<div className="wrapper">
				<section className="section">
					<div className="rr_wrapper">
						{isSubmitted
							? <ReturnRackConfirmation />
							: (
								<>
									<div className="rr_block rr_block__map">
										<div className="rr_block_heading">
											<div className="rr_block_heading__icon">
												{rackId}
											</div>
											<h2 className="rr_block_heading__title">{t('returnForRackFmt').replace('{}', rackId)}</h2>
											{optionalSubtitle && (
												<div className="rr_block_heading__subtitle">{optionalSubtitle}</div>
											)}
										</div>
										<ReturnRackMap mapData={mapData} setMapData={setMapData} />
									</div>

									<div className="rr_block rr_block__form">
										<div className="rr_block_heading">
											<div className="rr_block_heading__icon">
												<img alt="pos" src={PosImage} />
											</div>
											<h2 className="rr_block_heading__title">{t('pickupLocation')}</h2>
											<button
												type="button"
												className={`rr_track_position rr_track_position__${positionStatus} btn_v2 nolabel`}
												onClick={toggleGeoWatcher}
											>
												{
													trackLocation
														? (errorTrackingLocation
															? t('errorTrackingLocation')
															: t('doTrackLocation'))
														: t('dontTrackLocation')
												}
											</button>
										</div>
										<Formik
											innerRef={formRef}
											initialValues={{
												rackId,
												language: langApp,
												pickupStreet: '',
												pickupCity: '',
												companyName: '',
												contactName: '',
												comments: '',
											}}
											validate={(_values) => {
												const errors = {};
												if (!mapData.displayedAddress) {
													errors.pickupStreet = t('addressEmptyError');
												}

												return errors;
											}}
											onSubmit={async (values, {
												setSubmitting, setFieldTouched, setFieldError,
											}) => {
												try {
													setSubmitting(true);
													await sendRackPickupEmail(values);
													setIsSubmitted(true);
												} catch {
													setFieldTouched('submit', true);
													setFieldError('submit', t('errorSendingEmail'));
												} finally {
													setSubmitting(false);
												}
											}}
											validateOnChange={false}
											validator={() => ({})}
										>
											{({
												handleSubmit,
												handleBlur,
												handleChange,
												values,
												errors,
												touched,
												setFieldValue,
											}) => {
												return (
													<form className="rr_section_item rr_form" onSubmit={handleSubmit} noValidate>
														<input
															type="hidden"
															name="rackId"
															value={rackId}
															readOnly
														/>
														<input
															type="hidden"
															name="language"
															value={langApp}
															readOnly
														/>

														<div className="rr_form_item">
															<label htmlFor="pickupStreet">
																<span className="rr_label_text">{t('pickupStreet')}*</span>
																<ErrorMessage component="div" name="pickupStreet" className="rr_form_error" />
															</label>
															<input
																id="pickupStreet"
																name="pickupStreet"
																className="rr_form_input"
																type="text"
																defaultValue={addressFieldEnabled ? displayedStreet : undefined}
																value={addressFieldEnabled ? undefined : displayedStreet}
																onBlur={(e) => setDisplayedStreet(e.target.value)}
																placeholder={t('pickupStreet')}
																disabled={!addressFieldEnabled}
															/>
														</div>

														<div className="rr_form_item">
															<label>
																<span className="rr_label_text">{t('pickupCity')}*</span>
																<ErrorMessage component="div" name="pickupCity" className="rr_form_error" />
															</label>
															<input
																id="pickupCity"
																name="pickupCity"
																className="rr_form_input"
																type="text"
																defaultValue={addressFieldEnabled ? displayedCity : undefined}
																value={addressFieldEnabled ? undefined : displayedCity}
																onBlur={(e) => setDisplayedCity(e.target.value)}
																placeholder={t('pickupCityPlaceholder')}
																disabled={!addressFieldEnabled}
															/>
														</div>

														<div className="rr_form_item">
															<label htmlFor="companyName">
																<span className="rr_label_text">{t('companyName')}</span>
																<ErrorMessage component="div" name="companyName" className="rr_form_error" />
															</label>
															<input
																id="companyName"
																name="companyName"
																className="rr_form_input"
																type="text"
																defaultValue={companyName}
																onBlur={(e) => {
																	setFieldValue('companyName', e.target.value);
																	setCompanyName(e.target.value);
																}}
																placeholder={t('enterYourCompany')}
															/>
														</div>

														<div className="rr_form_item">
															<label htmlFor="contactName">
																<span className="rr_label_text">{t('contactName')}</span>
																<ErrorMessage component="div" name="contactName" className="rr_form_error" />
															</label>
															<input
																id="contactName"
																name="contactName"
																className="rr_form_input"
																type="text"
																defaultValue={contactName}
																onBlur={(e) => {
																	setFieldValue('contactName', e.target.value);
																	setContactName(e.target.value);
																}}
																placeholder={t('enterYourName')}
															/>
														</div>

														<div className="rr_form_item">
															<label htmlFor="comments">
																<span className="rr_label_text">{t('comments')}</span>
																<ErrorMessage component="div" name="comments" className="rr_form_error" />
															</label>
															<textarea
																id="comments"
																name="comments"
																className="rr_form_input rr_comments_field"
																defaultValue={comments}
																onChange={(e) => {
																	setFieldValue('comments', e.target.value);
																	setComments(e.target.value);
																}}
																placeholder={t('enterAnyComments')}
															/>
														</div>

														<div className="rr_form_item rr_form_item__submit">
															<button type="submit" className="btn_v2 nolabel">{t('submit')}</button>
															<ErrorMessage component="div" name="submit" className="rr_form_error rr_form_error__submit" />
														</div>
													</form>
												);
											}}
										</Formik>
									</div>
								</>
							)}
					</div>
				</section>
			</div>
		</>
	);
};

export default ReturnRackPage;
